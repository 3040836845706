<template>
  <div>
    <HomeOnethree/>
  </div>
</template>

<script>
import HomeOnethree from "../components/Home/_v3/index";

export default {
  components: {HomeOnethree},
};
</script>

<style>
</style>