<template>
  <div class="share-post-col">
    <div class="most-share-post">
      <div
          class="section-title"
          :class="[
          'section-title',
          darkClass && 'title' in darkClass ? darkClass.title : '',
        ]"
      >
        <h3 class="title">Most Share</h3>
      </div>
    </div>
    <div class="trending-sidebar-slider position-relative">
      <span
          @click="sharePostPrev"
          class="prev slick-arrow"
          style="display: block"
      ><i class="fal fa-angle-left"></i
      ></span>
      <slider :settings="trendingSidebarSlide" ref="sharePost">
        <div class="most-share-post-items">
          <template v-for="(data, index) in sharePosts.slice(0, 10)">
            <share-card
                :class="[darkClass && 'item' in darkClass ? darkClass.item : '']"
                :count="index + 1"
                :datas="data"
                :key="index"
            />
          </template>
        </div>
        <div class="most-share-post-items">
          <template v-for="(data, index) in sharePosts.slice(0, 10)">
            <share-card
                :class="[darkClass && 'item' in darkClass ? darkClass.item : '']"
                :count="index + 1"
                :datas="data"
                :key="index"
            />
          </template>
        </div>
      </slider>
      <span
          @click="sharePostNext"
          class="next slick-arrow"
          style="display: block"
      ><i class="fal fa-angle-right"></i
      ></span>
    </div>
  </div>
</template>

<script>
import ShareCard from "../Utility/Cards/ShareCard.vue";
import sharePosts from "../Data/sharePost";
import Slider from "../Helpers/Slider.vue";
// import axios from "axios";

export default {
  components: {ShareCard, Slider},
  props: {
    darkClass: {
      type: Object,
    },
  },
  data: () => ({
    sharePosts: sharePosts.data,
    trendingSidebarSlide: {
      arrows: false,
      slidesToShow: 1,
      slideToScroll: 1,
    },
  }),
  created() {
    this.fetchPostItems();
  },
  methods: {
    //share
    sharePostNext() {
      this.$refs.sharePost.next();
    },
    sharePostPrev() {
      this.$refs.sharePost.prev();
    },
    fetchPostItems() {
      this.$root.cachedAxios
          .get(this.$root.apiUrl + 'news/' + this.$root.zone + "?queryType=sharePosts") // Replace with your server URL
          .then(response => {
            this.sharePosts = response.data['data'];
          })
          .catch(error => {
            this.error = error;
            console.error('Error fetching data:', error);
          });
    },
  },
};
</script>

<style>
</style>