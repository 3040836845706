<template>
  <contact/>
</template>

<script>
import contact from "../components/Contacts/index";

export default {
  components: {contact},
};
</script>

<style></style>
