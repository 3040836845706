<template>
  <HomeDark/>
</template>

<script>
import HomeDark from "../components/HomeDark/index";

export default {
  components: {HomeDark},
};
</script>

<style></style>
