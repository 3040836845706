<template>
  <div>
    <category-layout>
      <section class="about-item-area">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="about-author-content pt-15">
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                      <a href="/">Home</a>
                    </li>
                    <li class="breadcrumb-item"><a href="#">Category</a></li>
                    <li class="breadcrumb-item active" aria-current="page">
                      Entertainment
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
            <div class="col-lg-8">
              <div class="about-tab-btn mt-40">
                <div class="archive-btn">
                  <ul>
                    <li>
                      <span>Category: <span>Entertainment</span></span>
                    </li>
                  </ul>
                </div>
                <div class="about-post-items">
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="bussiness-post-item mb-40">
                        <div class="bussiness-post-thumb">
                          <img
                              src="@/assets/images/business-post/business-post-1.jpg"
                              alt="business"
                          />
                        </div>
                        <div class="bussiness-post-content">
                          <h3 class="title">
                            <router-link to="/posts/postOne"
                            >She tried for many years now finallize pregnant
                              &amp; happy and
                            </router-link
                            >
                          </h3>
                          <div class="meta-date-link">
                            <span>April 26, 2020</span>
                            <ul>
                              <li>
                                <a href="#"><i class="fal fa-bookmark"></i></a>
                              </li>
                              <li>
                                <a href="#"><i class="fas fa-share"></i></a>
                              </li>
                            </ul>
                          </div>
                          <p>
                            She tried for so many years and now she finally
                            pregnant happy and things are going well &amp; it
                            just happens that this pregnancy takes place with
                            this epidemic…
                          </p>
                          <a href="#"
                          >LEARN MORE
                            <img src="@/assets/images/arrow-2.svg" alt=""
                            /></a>
                        </div>
                      </div>
                      <div class="bussiness-post-item mb-40">
                        <div class="bussiness-post-thumb">
                          <img
                              src="@/assets/images/business-post/business-post-2.jpg"
                              alt="business"
                          />
                        </div>
                        <div class="bussiness-post-content">
                          <h3 class="title">
                            <router-link to="/posts/postOne"
                            >She tried for many years now finallize pregnant
                              &amp; happy and
                            </router-link
                            >
                          </h3>
                          <div class="meta-date-link">
                            <span>April 26, 2020</span>
                            <ul>
                              <li>
                                <a href="#"><i class="fal fa-bookmark"></i></a>
                              </li>
                              <li>
                                <a href="#"><i class="fas fa-share"></i></a>
                              </li>
                            </ul>
                          </div>
                          <p>
                            She tried for so many years and now she finally
                            pregnant happy and things are going well &amp; it
                            just happens that this pregnancy takes place with
                            this epidemic…
                          </p>
                          <a href="#"
                          >LEARN MORE
                            <img src="@/assets/images/arrow-2.svg" alt=""
                            /></a>
                        </div>
                      </div>
                      <div class="bussiness-post-item mb-40">
                        <div class="bussiness-post-thumb">
                          <img
                              src="@/assets/images/business-post/business-post-3.jpg"
                              alt="business"
                          />
                        </div>
                        <div class="bussiness-post-content">
                          <h3 class="title">
                            <router-link to="/posts/postOne"
                            >She tried for many years now finallize pregnant
                              &amp; happy and
                            </router-link
                            >
                          </h3>
                          <div class="meta-date-link">
                            <span>April 26, 2020</span>
                            <ul>
                              <li>
                                <a href="#"><i class="fal fa-bookmark"></i></a>
                              </li>
                              <li>
                                <a href="#"><i class="fas fa-share"></i></a>
                              </li>
                            </ul>
                          </div>
                          <p>
                            She tried for so many years and now she finally
                            pregnant happy and things are going well &amp; it
                            just happens that this pregnancy takes place with
                            this epidemic…
                          </p>
                          <a href="#"
                          >LEARN MORE
                            <img src="@/assets/images/arrow-2.svg" alt=""
                            /></a>
                        </div>
                      </div>
                      <div class="bussiness-post-item mb-40">
                        <div class="bussiness-post-thumb">
                          <img
                              src="@/assets/images/business-post/business-post-4.jpg"
                              alt="business"
                          />
                        </div>
                        <div class="bussiness-post-content">
                          <h3 class="title">
                            <router-link to="/posts/postOne"
                            >She tried for many years now finallize pregnant
                              &amp; happy and
                            </router-link
                            >
                          </h3>
                          <div class="meta-date-link">
                            <span>April 26, 2020</span>
                            <ul>
                              <li>
                                <a href="#"><i class="fal fa-bookmark"></i></a>
                              </li>
                              <li>
                                <a href="#"><i class="fas fa-share"></i></a>
                              </li>
                            </ul>
                          </div>
                          <p>
                            She tried for so many years and now she finally
                            pregnant happy and things are going well &amp; it
                            just happens that this pregnancy takes place with
                            this epidemic…
                          </p>
                          <a href="#"
                          >LEARN MORE
                            <img src="@/assets/images/arrow-2.svg" alt=""
                            /></a>
                        </div>
                      </div>
                      <div class="bussiness-post-item mb-40">
                        <div class="bussiness-post-thumb">
                          <img
                              src="@/assets/images/business-post/business-post-5.jpg"
                              alt="business"
                          />
                        </div>
                        <div class="bussiness-post-content">
                          <h3 class="title">
                            <router-link to="/posts/postOne"
                            >She tried for many years now finallize pregnant
                              &amp; happy and
                            </router-link
                            >
                          </h3>
                          <div class="meta-date-link">
                            <span>April 26, 2020</span>
                            <ul>
                              <li>
                                <a href="#"><i class="fal fa-bookmark"></i></a>
                              </li>
                              <li>
                                <a href="#"><i class="fas fa-share"></i></a>
                              </li>
                            </ul>
                          </div>
                          <p>
                            She tried for so many years and now she finally
                            pregnant happy and things are going well &amp; it
                            just happens that this pregnancy takes place with
                            this epidemic…
                          </p>
                          <a href="#"
                          >LEARN MORE
                            <img src="@/assets/images/arrow-2.svg" alt=""
                            /></a>
                        </div>
                      </div>
                      <div class="bussiness-post-item mb-40">
                        <div class="bussiness-post-thumb">
                          <img
                              src="@/assets/images/business-post/business-post-6.jpg"
                              alt="business"
                          />
                        </div>
                        <div class="bussiness-post-content">
                          <h3 class="title">
                            <router-link to="/posts/postOne"
                            >She tried for many years now finallize pregnant
                              &amp; happy and
                            </router-link
                            >
                          </h3>
                          <div class="meta-date-link">
                            <span>April 26, 2020</span>
                            <ul>
                              <li>
                                <a href="#"><i class="fal fa-bookmark"></i></a>
                              </li>
                              <li>
                                <a href="#"><i class="fas fa-share"></i></a>
                              </li>
                            </ul>
                          </div>
                          <p>
                            She tried for so many years and now she finally
                            pregnant happy and things are going well &amp; it
                            just happens that this pregnancy takes place with
                            this epidemic…
                          </p>
                          <a href="#"
                          >LEARN MORE
                            <img src="@/assets/images/arrow-2.svg" alt=""
                            /></a>
                        </div>
                      </div>
                      <div class="bussiness-post-item mb-40">
                        <div class="bussiness-post-thumb">
                          <img
                              src="@/assets/images/business-post/business-post-1.jpg"
                              alt="business"
                          />
                        </div>
                        <div class="bussiness-post-content">
                          <h3 class="title">
                            <router-link to="/posts/postOne"
                            >She tried for many years now finallize pregnant
                              &amp; happy and
                            </router-link
                            >
                          </h3>
                          <div class="meta-date-link">
                            <span>April 26, 2020</span>
                            <ul>
                              <li>
                                <a href="#"><i class="fal fa-bookmark"></i></a>
                              </li>
                              <li>
                                <a href="#"><i class="fas fa-share"></i></a>
                              </li>
                            </ul>
                          </div>
                          <p>
                            She tried for so many years and now she finally
                            pregnant happy and things are going well &amp; it
                            just happens that this pregnancy takes place with
                            this epidemic…
                          </p>
                          <a href="#"
                          >LEARN MORE
                            <img src="@/assets/images/arrow-2.svg" alt=""
                            /></a>
                        </div>
                      </div>
                      <div class="bussiness-post-item mb-40">
                        <div class="bussiness-post-thumb">
                          <img
                              src="@/assets/images/business-post/business-post-2.jpg"
                              alt="business"
                          />
                        </div>
                        <div class="bussiness-post-content">
                          <h3 class="title">
                            <router-link to="/posts/postOne"
                            >She tried for many years now finallize pregnant
                              &amp; happy and
                            </router-link
                            >
                          </h3>
                          <div class="meta-date-link">
                            <span>April 26, 2020</span>
                            <ul>
                              <li>
                                <a href="#"><i class="fal fa-bookmark"></i></a>
                              </li>
                              <li>
                                <a href="#"><i class="fas fa-share"></i></a>
                              </li>
                            </ul>
                          </div>
                          <p>
                            She tried for so many years and now she finally
                            pregnant happy and things are going well &amp; it
                            just happens that this pregnancy takes place with
                            this epidemic…
                          </p>
                          <a href="#"
                          >LEARN MORE
                            <img src="@/assets/images/arrow-2.svg" alt=""
                            /></a>
                        </div>
                      </div>
                      <div class="bussiness-post-item mb-40">
                        <div class="bussiness-post-thumb">
                          <img
                              src="@/assets/images/business-post/business-post-3.jpg"
                              alt="business"
                          />
                        </div>
                        <div class="bussiness-post-content">
                          <h3 class="title">
                            <router-link to="/posts/postOne"
                            >She tried for many years now finallize pregnant
                              &amp; happy and
                            </router-link
                            >
                          </h3>
                          <div class="meta-date-link">
                            <span>April 26, 2020</span>
                            <ul>
                              <li>
                                <a href="#"><i class="fal fa-bookmark"></i></a>
                              </li>
                              <li>
                                <a href="#"><i class="fas fa-share"></i></a>
                              </li>
                            </ul>
                          </div>
                          <p>
                            She tried for so many years and now she finally
                            pregnant happy and things are going well &amp; it
                            just happens that this pregnancy takes place with
                            this epidemic…
                          </p>
                          <a href="#"
                          >LEARN MORE
                            <img src="@/assets/images/arrow-2.svg" alt=""
                            /></a>
                        </div>
                      </div>
                      <div class="bussiness-post-item mb-40">
                        <div class="bussiness-post-thumb">
                          <img
                              src="@/assets/images/business-post/business-post-4.jpg"
                              alt="business"
                          />
                        </div>
                        <div class="bussiness-post-content">
                          <h3 class="title">
                            <router-link to="/posts/postOne"
                            >She tried for many years now finallize pregnant
                              &amp; happy and
                            </router-link
                            >
                          </h3>
                          <div class="meta-date-link">
                            <span>April 26, 2020</span>
                            <ul>
                              <li>
                                <a href="#"><i class="fal fa-bookmark"></i></a>
                              </li>
                              <li>
                                <a href="#"><i class="fas fa-share"></i></a>
                              </li>
                            </ul>
                          </div>
                          <p>
                            She tried for so many years and now she finally
                            pregnant happy and things are going well &amp; it
                            just happens that this pregnancy takes place with
                            this epidemic…
                          </p>
                          <a href="#"
                          >LEARN MORE
                            <img src="@/assets/images/arrow-2.svg" alt=""
                            /></a>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="bussiness-btn">
                        <a class="main-btn main-btn-2" href="#">See more</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <style-one :datas="posts.slice(1, 6)" :sharePost="false"/>
            </div>
          </div>
        </div>
      </section>
    </category-layout>
  </div>
</template>

<script>
import CategoryLayout from "./CategoryLayout.vue";
import Posts from "../Data/TrendingHomeThree";
import StyleOne from "../Utility/Sidebar/StyleOne.vue";

export default {
  components: {CategoryLayout, StyleOne},
  data: () => ({
    posts: Posts.data,
    // category:,
  }),
};
</script>

<style></style>
