import Vue from 'vue'
import App from './App.vue'
import router from './router'
import '../src/assets/css/bootstrap.min.css'
import '../src/assets/css/font-awesome.min.css'
import '../src/assets/css/magnific-popup.css'
import '../src/assets/css/default.css'
import '../src/assets/css/style.css'
import '../src/assets/css/custom.css'
import {store} from './store/index'
import Axios from 'axios';
import { setupCache } from 'axios-cache-interceptor';
// import { setupCache } from 'axios-cache-interceptor/dev';

Vue.config.productionTip = false

// Determine the environment
// const env = process.env.NODE_ENV || 'dev';
const env = 'prod';

// Load the corresponding configuration file
const config = require(`./config.${env}.js`);

const cachedAxios = Axios.create();
setupCache(cachedAxios, {
    maxAge: 12 * 60 * 60 * 1000,
    // debug: console.log
});

new Vue({
    store,
    router,
    render: h => h(App),
    data() {
        return {
            apiUrl: config.apiUrl,
            zone: config.zone,
            cachedAxios: cachedAxios
        };
    }
}).$mount('#app')
