<template>
  <home-two/>
</template>

<script>
import HomeTwo from "../components/HomeTwo/index";

export default {
  components: {HomeTwo},
};
</script>

<style>
</style>