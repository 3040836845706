var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
      'post_gallery_sidebar',
      _vm.darkClass && 'section' in _vm.darkClass ? _vm.darkClass.section : '',
    ]},[_c('ul',{staticClass:"nav nav-pills",attrs:{"id":"pills-tab","role":"tablist"}},[_c('li',{staticClass:"nav-item",on:{"click":function($event){$event.preventDefault();return _vm.selectGalleryTab('trendy')}}},[_c('a',{class:['nav-link', _vm.selectedGallery === 'trendy' ? 'active' : ''],attrs:{"data-toggle":"pill","role":"tab","aria-controls":"pills-home","aria-selected":"true"}},[_vm._v("TRENDY")])]),_c('li',{staticClass:"nav-item",on:{"click":function($event){$event.preventDefault();return _vm.selectGalleryTab('latest')}}},[_c('a',{class:['nav-link', _vm.selectedGallery === 'latest' ? 'active' : ''],attrs:{"data-toggle":"pill","href":"#pills-profile","role":"tab","aria-controls":"pills-profile","aria-selected":"false"}},[_vm._v("LATEST")])]),_c('li',{staticClass:"nav-item",on:{"click":function($event){$event.preventDefault();return _vm.selectGalleryTab('popular')}}},[_c('a',{staticClass:"nav-link",class:['nav-link', _vm.selectedGallery === 'popular' ? 'active' : ''],attrs:{"data-toggle":"pill","href":"#pills-contact","role":"tab","aria-controls":"pills-contact","aria-selected":"false"}},[_vm._v("POPULAR")])])]),_c('div',{staticClass:"tab-content"},[_c('div',{class:[
          'tab-pane fade',
          _vm.selectedGallery === 'trendy' ? 'show active' : '',
        ],attrs:{"role":"tabpanel","aria-labelledby":"pills-home-tab"}},[(_vm.datas && _vm.datas.length > 0)?_c('div',{staticClass:"post_gallery_items"},[_vm._l((_vm.datas.slice(0, 5)),function(small,index){return [_c('row-card',{key:index,class:[_vm.darkClass && 'item' in _vm.darkClass ? _vm.darkClass.item : ''],attrs:{"category":true,"datas":small}})]})],2):_vm._e()])]),_c('div',{staticClass:"tab-content"},[_c('div',{class:[
          'tab-pane fade',
          _vm.selectedGallery === 'latest' ? 'show active' : '',
        ],attrs:{"role":"tabpanel","aria-labelledby":"pills-home-tab"}},[(_vm.datas && _vm.datas.length > 0)?_c('div',{staticClass:"post_gallery_items"},[_vm._l((_vm.datas.slice(5, 10)),function(small,index){return [_c('row-card',{key:index,attrs:{"category":true,"datas":small}})]})],2):_vm._e()])]),_c('div',{staticClass:"tab-content"},[_c('div',{class:[
          'tab-pane fade',
          _vm.selectedGallery === 'popular' ? 'show active' : '',
        ],attrs:{"role":"tabpanel","aria-labelledby":"pills-home-tab"}},[(_vm.datas && _vm.datas.length > 0)?_c('div',{staticClass:"post_gallery_items"},[_vm._l((_vm.datas.slice(10, 14)),function(small,index){return [_c('row-card',{key:index,attrs:{"category":true,"datas":small}})]})],2):_vm._e()])]),(_vm.signup)?_c('signup-style-two'):_vm._e(),(_vm.trendingBigPost)?_c('div',{staticClass:"trending-sidebar mt-40"},[_vm._m(0),_c('div',{staticClass:"trending-sidebar-slider position-relative"},[_c('span',{staticClass:"prev slick-arrow",staticStyle:{"display":"block"},on:{"click":function($event){$event.preventDefault();return _vm.trendingNewsPrev.apply(null, arguments)}}},[_c('i',{staticClass:"fal fa-angle-left"})]),_c('slider',{ref:"trendingNews",attrs:{"settings":_vm.trendingNews}},[_vm._l((_vm.datas),function(data,index){return [_c('divide-card',{key:index,attrs:{"stype":"col","datas":data}})]})],2),_c('span',{staticClass:"next slick-arrow",staticStyle:{"display":"block"},on:{"click":function($event){$event.preventDefault();return _vm.trendingNewsNext.apply(null, arguments)}}},[_c('i',{staticClass:"fal fa-angle-right"})])],1)]):_vm._e(),(_vm.ad)?_c('div',{staticClass:"sidebar-add pt-35"},[_vm._m(1)]):_vm._e(),(_vm.sharePost)?_c('share-post',{staticClass:"mt-40"}):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section-title"},[_c('h3',{staticClass:"title"},[_vm._v("Trending News")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"#"}},[_c('img',{attrs:{"src":require("@/assets/images/ads/two_ad.jpg"),"alt":"ad"}})])
}]

export { render, staticRenderFns }