<template>
  <div>
    <category-layout>
      <section class="about-item-area">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="about-author-content pt-15">
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                      <a href="/">Home</a>
                    </li>
                    <li class="breadcrumb-item">Category</li>
                    <li class="breadcrumb-item active" aria-current="page">
                      {{ $route.params.category }}
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
            <div class="col-lg-8">
              <div class="about-tab-btn mt-40">
                <div class="archive-btn">
                  <div class="pagination-item">
                    <nav aria-label="Page navigation example">
                      <ul class="pagination">
                        <li class="page-item">
                          <a class="page-link"  aria-label="Prev" v-on:click="currentpage=currentpage-1;fetchPostItems(currentpage)">
                                <span aria-hidden="true"
                                ><i class="fas fa-caret-left"></i
                                ></span>
                          </a>
                        </li>
                        <li>
                          <span>{{ $route.params.category }}</span>
                        </li>
                        <li class="page-item">
                          <a class="page-link" aria-label="Next" v-on:click="currentpage=currentpage+1;fetchPostItems(currentpage)">
                                <span aria-hidden="true"
                                ><i class="fas fa-caret-right"></i
                                ></span>
                          </a>
                        </li>
                      </ul>
                    </nav>
                  </div>

                </div>
                <div class="about-post-items">
                  <div class="row">
                    <div class="col-lg-12">
                      <template v-for="(data, index) in posts">
                        <div :key="index" class="business-post-item  mb-40">
                          <divide-card :readMore="true" stype="row" :datas="data"/>
                        </div>
                      </template>
                    </div>
                    <div class="col-lg-12">
                      <div class="pagination-item">
                        <nav aria-label="Page navigation example">
                          <ul class="pagination">
                            <li class="page-item">
                              <a class="page-link"  aria-label="Prev" v-on:click="currentpage=currentpage-1;fetchPostItems(currentpage)">
                                <span aria-hidden="true"
                                ><i class="fas fa-caret-left"></i
                                ></span>
                              </a>
                            </li>
                            <li class="page-item">
                              <a class="page-link" aria-label="Next" v-on:click="currentpage=currentpage+1;fetchPostItems(currentpage)">
                                <span aria-hidden="true"
                                ><i class="fas fa-caret-right"></i
                                ></span>
                              </a>
                            </li>
                          </ul>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <style-one :datas="randomPost" :sharePost="false"/>
            </div>
          </div>
        </div>
      </section>
    </category-layout>
  </div>
</template>

<script>
import StyleOne from "../Utility/Sidebar/StyleOne.vue";
import CategoryLayout from "./CategoryLayout.vue";
import Posts from "../Data/TrendingHomeThree";
// import axios from "axios";
import DivideCard from "@/components/Utility/Cards/DivideCard.vue";

export default {
  components: {DivideCard, CategoryLayout, StyleOne},
  data: () => ({
    currentpage:1,
    posts: Posts.data,
    randomPost:Posts.data,
  }),
  created() {
    this.fetchPostItems(1);
  },
  methods: {
    fetchPostItems(pageNumber) {
      this.$root.cachedAxios
          .get(this.$root.apiUrl + 'news/' + this.$root.zone + "?queryType=category&pageNumber=" + pageNumber + "&pageSize=15&category=" + this.$route.params.category) // Replace with your server URL
          .then(response => {
            this.posts = response.data['data'];
          })
          .catch(error => {
            this.error = error;
            console.error('Error fetching data:', error);
          });
      this.$root.cachedAxios
          .get(this.$root.apiUrl + 'news/' + this.$root.zone + "?queryType=styleOne") // Replace with your server URL
          .then(response => {
            this.randomPost = response.data['data'];
          })
          .catch(error => {
            this.error = error;
            console.error('Error fetching data:', error);
          });
    },
  },
};
</script>

<style></style>
