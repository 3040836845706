<template>
  <div>
    <about/>
  </div>
</template>

<script>
import About from "../components/About/index";

export default {
  components: {About},
};
</script>

<style></style>
