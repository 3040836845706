<template>
  <div>
    <HomeOnefour/>
  </div>
</template>

<script>
import HomeOnefour from "../components/Home/_v4/index";

export default {
  components: {HomeOnefour},
};
</script>

<style>
</style>