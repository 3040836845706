<template>
  <HomeThreeThree/>
</template>

<script>
import HomeThreeThree from "../components/HomeThree/_v3/index";

export default {
  components: {HomeThreeThree},
};
</script>

<style>
</style>