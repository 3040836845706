var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sports-news-area"},[_c('div',{class:[
      'section-title',
      _vm.darkClass && 'title' in _vm.darkClass ? _vm.darkClass.title : '',
    ]},[_c('h3',{staticClass:"title"},[_vm._v("Sports News")])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6 col-md-6"},[_c('divide-card',{class:[_vm.darkClass && 'item' in _vm.darkClass ? _vm.darkClass.item : ''],attrs:{"stype":"col","datas":_vm.sportNews[0]}})],1),_c('div',{staticClass:"col-lg-6 col-md-6"},[_c('div',{staticClass:"trending-sidebar-slider"},[_c('span',{staticClass:"prev slick-arrow d-md-block d-none",on:{"click":_vm.sportSliderPrev}},[_c('i',{staticClass:"fal fa-angle-left"})]),_c('slider',{ref:"sportSlider",attrs:{"settings":_vm.trendingSidebarSlide}},[_c('div',{staticClass:"post_gallery_items"},[_vm._l((_vm.sportNews.slice(5, 10)),function(data,index){return [_c('row-card',{key:index,class:[
                  _vm.darkClass && 'news_item' in _vm.darkClass
                    ? _vm.darkClass.news_item
                    : '',
                ],attrs:{"sports":true,"datas":data}})]})],2),_c('div',{staticClass:"post_gallery_items"},[_vm._l((_vm.sportNews.slice(0, 5)),function(data,index){return [_c('row-card',{key:index,class:[
                  _vm.darkClass && 'news_item' in _vm.darkClass
                    ? _vm.darkClass.news_item
                    : '',
                ],attrs:{"sports":true,"datas":data}})]})],2)]),_c('span',{staticClass:"next slick-arrow d-md-block d-none",on:{"click":_vm.sportSliderNext}},[_c('i',{staticClass:"fal fa-angle-right"})])],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }