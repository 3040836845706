<template>
  <a :href="'/posts/postOne/' + datas.id"
     v-if="stype === 'small'"
     class="feature-post d-block"
     target="_blank"
  >
    <div class="feature-post-thumb">
      <!--      <img :src="require(`@/assets/images/` + datas.image)" alt="feature" />-->
      <img width="255"
           height="320"
           v-if="datas.image"
           :src="datas.image"
           @error="imageloaderror"
           alt="gallery"
      />
    </div>
    <div class="feature-post-content">
      <div class="post-meta">
        <div class="meta-categories">
          <a href="#">{{ datas.category.name }}</a>
        </div>
        <div class="meta-date">
          <span>{{ datas.category.create_At }}</span>
        </div>
      </div>
      <h4 class="title">
        <a :href="'/posts/postOne/' + datas.id" target="_blank">{{ datas.title }}</a>
      </h4>
    </div>
  </a>
  <a :href="'/posts/postOne/' + datas.id"
      v-else-if="stype === 'big'"
      class="single-play-post-item d-block"
     target="_blank"
  >
    <img height="320"
         v-if="datas.image"
         :src="datas.image"
         @error="imageloaderror" alt="feature"/>

    <div class="single-play-post-content">
      <div class="post-meta">
        <div class="meta-categories">
          <a href="#">{{ datas.category.name }}</a>
        </div>
        <div class="meta-date">
          <span>{{ datas.category.create_At }}</span>
        </div>
      </div>
      <h4 class="title">
        <a :href="'/posts/postOne/' + datas.id" target="_blank">{{ datas.title }}</a>
      </h4>
    </div>
    <div v-if="datas.action === 'play'" class="play-btn">
      <a class="video-popup" @click.prevent="$store.dispatch('toggleVideo')"
      ><i class="fas fa-play"></i
      ></a>
    </div>
    <div v-if="datas.action === 'trending'" class="trending-btn">
      <a href="#"><i class="fas fa-bolt"></i></a>
    </div>
  </a>
</template>

<script>
export default {
  props: {
    datas: {
      type: Object,
    },
    stype: {
      type: String,
    },
  },
  methods: {
    imageloaderror(event) {
      event.target.src = `https://source.unsplash.com/random/400x300?count=`+Math.random();
    }
  }
};
</script>

<style></style>
