<template>
  <DarkFour/>
</template>

<script>
import DarkFour from "../components/HomeDark/_v4/index";

export default {
  components: {DarkFour},
};
</script>

<style>
</style>