<template>
  <a :href="'/posts/postOne/' + datas.id" v-if="category" target="_blank" class="gallery_item">
    <template v-if="datas">
      <div class="gallery_item_thumb">
        <img width="100"
             height="100"
             v-if="datas.image"
             :src="datas.image"
             @error="imageloaderror"
             alt="gallery"
        />
      </div>
      <div class="gallery_item_content">
        <div class="post-meta">
          <div class="meta-categories">
            <a href="#">{{ datas.category.name }}</a>
          </div>
          <div class="meta-date">
            <span>{{ datas.category.create_At }}</span>
          </div>
        </div>
        <h4 class="title">
          <a :href="'/posts/postOne/' + datas.id" target="_blank">{{ datas.title }}</a>
        </h4>
      </div>
    </template>
  </a>
  <a :href="'/posts/postOne/' + datas.id" v-else-if="countingtwo" target="_blank" class="post-gallery-style-2 most-view-style-2">
    <div class="post-gallery-thumb">
      <img width="100"
           height="100"
           v-if="datas.image"
           :src="datas.image"
           @error="imageloaderror"
           alt="gallery"
      />
      <span>{{ count }}</span>
    </div>
    <div class="post-gallery-content">
      <h5 class="title">
        <a :href="'/posts/postOne/' + datas.id" target="_blank">{{ datas.title }}</a>
      </h5>
      <div class="meta-post-2-style">
        <div class="meta-post-categores">
          <a href="#">{{ datas.category.name }}</a>
        </div>
        <div class="meta-post-date">
          <span>{{ datas.category.create_At }}</span>
        </div>
      </div>
    </div>
  </a>
  <a :href="'/posts/postOne/' + datas.id" v-else-if="trending" target="_blank" class="gallery_item">
    <div class="gallery_item_thumb">
      <img width="100"
           height="100"
           v-if="datas.image"
           :src="datas.image"
           @error="imageloaderror"
           alt="gallery"
      />
      <div v-if="datas.trending" class="icon"><i class="fas fa-bolt"></i></div>
    </div>
    <div class="gallery_item_content">
      <div class="post-meta">
        <div class="meta-categories">
          <a href="#">{{ datas.category.name }}</a>
        </div>
        <div class="meta-date">
          <span>{{ datas.category.create_At }}</span>
        </div>
      </div>
      <h4 class="title">
        <a :href="'/posts/postOne/' + datas.id" target="_blank">{{ datas.title }}</a>
      </h4>
      <!-- <span v-if="counting">{{ counting }}</span> -->
    </div>
  </a>
  <a :href="'/posts/postOne/' + datas.id" v-else-if="counting" target="_blank" class="gallery_item gallery_item-style-2">
    <div class="gallery_item_thumb">
      <img width="100"
           height="100"
           v-if="datas.image"
           :src="datas.image"
           @error="imageloaderror"
           alt="gallery"
      />
      <div class="icon"><i class="fas fa-bolt"></i></div>
    </div>
    <div class="gallery_item_content">
      <div class="post-meta">
        <div class="meta-categories">
          <a href="#">{{ datas.category.name }}</a>
        </div>
        <div class="meta-date">
          <span>March 26, 2020</span>
        </div>
      </div>
      <h4 class="title">
        <a :href="'/posts/postOne/' + datas.id" target="_blank">{{ datas.title }}</a>
      </h4>
      <span>{{ count }}</span>
    </div>
  </a>
  <a :href="'/posts/postOne/' + datas.id" v-else-if="sports" target="_blank" class="gallery_item">
    <template v-if="datas">
      <div class="gallery_item_thumb">
        <img width="100"
             height="100"
             v-if="datas.image"
             :src="datas.image"
             @error="imageloaderror"
             alt="gallery"
        />
      </div>
      <div class="gallery_item_content">
        <div class="post-meta">
          <div class="meta-categories">
            <a href="#">{{ datas.category.name }}</a>
          </div>
          <div class="meta-date">
            <span>{{ datas.category.create_At }}</span>
          </div>
        </div>
        <h4 class="title">
          <a :href="'/posts/postOne/' + datas.id" target="_blank">{{ datas.title }}</a>
        </h4>
      </div>
    </template>
  </a>
  <a :href="'/posts/postOne/' + datas.id" v-else class="single__post d-lg-flex text-center text-lg-left"
     target="_blank">
    <template v-if="datas">
      <div class="post-thumb mb-3 mb-lg-0">
        <img width="100"
             height="100"
             v-if="datas.image"
             :src="datas.image"
             @error="imageloaderror"
             alt="gallery"/>
      </div>
      <div class="post-content">
        <h4 class="title">
          <a :href="'/posts/postOne/' + datas.id" class="line-clumb" target="_blank">{{ datas.title }}</a>
        </h4>
        <p class="line-clumb-one">{{ datas.description }}</p>
      </div>
    </template>
  </a>
</template>
<script>

export default {
  props: {
    category: {
      type: Boolean,
    },
    trending: {
      type: Boolean,
    },
    sports: {type: Boolean, default: false},
    datas: {
      type: Object,
    },
    counting: {
      type: Boolean,
    },
    countingtwo: {
      type: Boolean,
    },
    count: {
      type: Number,
    },
  },
  methods: {
    imageloaderror(event) {
      event.target.src = `https://source.unsplash.com/random/400x300?count=`+Math.random();
    },
  }
};
</script>

<style></style>
<script setup>
</script>