<template>
  <div class="post__gallery__area">
    <div class="container">
      <div class="row">
        <div class="col-lg-8">
          <div class="post_gallery_slider">
            <slider :settings="BigCardsSettings" ref="BigCards">
              <template v-for="(single, index) in postGallery">
                <big-video-card-with-des :datas="single" :key="index"/>
              </template>
            </slider>
          </div>
          <div class="post_gallery_inner_slider" style="position: relative">
            <span
                @click="postGalleryPrev"
                class="prev slick-arrow"
                style="display: block"
            ><i class="fal fa-angle-left"></i
            ></span>
            <slider :settings="miniCardsSettings" ref="miniCards">
              <template v-for="(multi, index) in postGallery">
                <div class="item" :key="index">
                  <img height="100"
                      width="100"
                       style="margin-right: 10px"
                      :src="multi.picture"
                      @error="imageloaderror"
                      alt=""
                  />
                </div>
              </template>
            </slider>
            <span
                @click="postGalleryNext"
                class="next slick-arrow"
                style="display: block"
            ><i class="fal fa-angle-right"></i
            ></span>
          </div>
        </div>
        <div class="col-lg-4">
          <home-one
              :trendingShortPost="false"
              :signup="false"
              :trendingBigPost="false"
              :ad="false"
              :sharePost="false"
              :darkClass="darkClass"
              role="sidebar"
              :datas="sidebarPostGallery"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import postGallery from "../Data/BigVideoCardWithDes";
import Slider from "../Helpers/Slider.vue";
import sidebarPostGallery from "../Data/NewsRowCard";
import BigVideoCardWithDes from "../Utility/Cards/BigVideoCardWithDes.vue";
import HomeOne from "../Utility/Sidebar/StyleOne.vue";
// import axios from "axios";

export default {
  components: {Slider, BigVideoCardWithDes, HomeOne},
  props: {
    darkClass: {
      type: Object,
      default: () => {
      },
    },
  },
  data: () => ({
    //post gallery
    postGallery: postGallery.data,
    sidebarPostGallery: sidebarPostGallery.data,
    selectedGallery: "trendy",
    miniCardsSettings: {
      slidesToShow: 8,
      slideToScroll: 1,
      autoplay: true,
      arrows: false,
      responsive: [
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 3,
            slideToScroll: 1,
            autoplay: true,
          },
        },
      ],
    },
    BigCardsSettings: {
      slidesToShow: 1,
      slideToScroll: 1,
      autoplay: true,
      arrows: false,
    },
  }),
  created() {
    this.fetchPostItems();
  },
  methods: {
    //post gallery
    postGalleryPrev() {
      this.$refs.BigCards.prev();
      this.$refs.miniCards.prev();
    },
    postGalleryNext() {
      this.$refs.BigCards.next();
      this.$refs.miniCards.next();
    },
    selectGalleryTab(value) {
      this.selectedGallery = value;
    },
    fetchPostItems() {
      this.$root.cachedAxios.get(this.$root.apiUrl + 'news/' + this.$root.zone + "?queryType=sidebarPostGallery")
          .then(response => {
            this.sidebarPostGallery = response.data['data'];
          })
          .catch(error => {
            this.error = error;
            console.error('Error fetching data:', error);
          });
      this.$root.cachedAxios.get(this.$root.apiUrl + 'news/' + this.$root.zone + "?queryType=bigVideoCardWithDes")
          .then(response => {
            this.postGallery = response.data['data'];
          })
          .catch(error => {
            this.error = error;
            console.error('Error fetching data:', error);
          });
    },
    imageloaderror(event) {
      event.target.src = `https://source.unsplash.com/random/400x300?count=`+Math.random();
    }
  },
};
</script>

<style></style>
