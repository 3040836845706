<template>
  <section class="video-news-area">
    <div class="container custom-container">
      <div
          :class="[
          'video-news-box',
          darkClass && 'section' in darkClass ? darkClass.section : '',
        ]"
      >
        <div class="row">
          <div class="col-lg-8">
            <div class="video-news-post">
              <div class="section-title section-title-2">
                <h3 class="title">Videos News</h3>
              </div>
              <div :class="[
                  'video-news-post-item',
                  darkClass && 'section_2' in darkClass
                    ? darkClass.section_2
                    : '',
                ]">
                <div class="video-news-post-thumb">
                  <!--                  <img src="@/assets/images/video-post-thumb.jpg" alt="" />-->
                  <img height="422"
                       v-if="smallPostGallery[0].image"
                       :src="smallPostGallery[0].image"
                       @error="imageloaderror"
                       alt="gallery"
                  />
                  <div class="play-btn">
                    <a class="video-popup"
                       @click.prevent="$store.dispatch('toggleVideo')"
                    ><i class="fab fa-youtube"></i
                    ></a>
                  </div>
                </div>
                <div class="video-news-post-content">
                  <div class="post-meta">
                    <div class="meta-categories">
                      <a href="#">{{smallPostGallery[0].category.name}}</a>
                    </div>
                    <div class="meta-date">
                      <span>{{smallPostGallery[0].publish_time}}</span>
                    </div>
                  </div>
                  <h3 class="title">
                    <a :href="'/posts/postOne/' + smallPostGallery[0].id" class="line-clumb" target="_blank">
                    {{smallPostGallery[0].description}}
                    </a>
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="populer-post">
              <div :class="['section-title', dark ? 'section-title-2' : '']">
                <h3 class="title">Popular</h3>
              </div>
              <div class="trending-sidebar-slider position-relative">
                <span
                    @click="tssPrev"
                    class="prev slick-arrow"
                    style="display: block"
                ><i class="fal fa-angle-left"></i
                ></span>
                <slider :settings="videoNewsSlide" ref="trendingSidebarSlide">
                  <div class="post_gallery_items">
                    <template v-for="(small, index) in smallPostGallery.slice(4, 7)">
                      <row-card
                          :class="[
                          'populer_item-style',
                          darkClass && 'news_item' in darkClass
                            ? darkClass.news_item
                            : '',
                        ]"
                          :key="index"
                          :trending="true"
                          :datas="small"
                      />
                    </template>
                  </div>
                  <div class="post_gallery_items">
                    <template v-for="(small, index) in smallPostGallery.slice(7, 10)">
                      <row-card
                          :class="[
                          'populer_item-style',
                          darkClass && 'news_item' in darkClass
                            ? darkClass.news_item
                            : '',
                        ]"
                          :key="index"
                          :trending="true"
                          :datas="small"
                      />
                    </template>
                  </div>
                </slider>
                <span
                    @click="tssNext"
                    class="next slick-arrow"
                    style="display: block"
                ><i class="fal fa-angle-right"></i
                ></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import smallPostGallery from "../Data/NewsRowCard";
import Slider from "../Helpers/Slider.vue";
import RowCard from "../Utility/Cards/RowCard.vue";
// import axios from "axios";

export default {
  components: {Slider, RowCard},
  props: {
    darkClass: {
      type: Object,
    },
    dark: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    smallPostGallery: smallPostGallery.data,
    videoNewsSlide: {
      arrows: false,
      slidesToShow: 1,
      slideToScroll: 1,
    },
  }),
  created() {
    this.fetchPostItems();
  },
  methods: {
    tssPrev() {
      this.$refs.trendingSidebarSlide.prev();
    },
    tssNext() {
      this.$refs.trendingSidebarSlide.next();
    },
    fetchPostItems() {
      this.$root.cachedAxios
          .get(this.$root.apiUrl + 'news/' + this.$root.zone + "?queryType=popular") // Replace with your server URL
          .then(response => {
            this.smallPostGallery = response.data['data'];
          })
          .catch(error => {
            this.error = error;
            console.error('Error fetching data:', error);
          });
    },
    imageloaderror(event) {
      event.target.src = `https://source.unsplash.com/random/400x300?count=`+Math.random();
    },
  },
};
</script>

<style></style>
