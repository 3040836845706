var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"all-post-area"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-lg-8"},[(!_vm.dark)?_c('entertainment-news'):_c('entertainment-news',{attrs:{"darkClass":{
              item: 'trending-news-item-dark',
              title: 'section-title-2',
            }}}),(!_vm.dark)?_c('sports-news'):_c('sports-news',{attrs:{"darkClass":{
              item: 'trending-news-item-dark',
              title: 'section-title-2',
              news_item: 'gallery_item_dark',
            }}}),_vm._m(0),(!_vm.dark)?_c('buisness-news',{staticClass:"pt-40"}):_c('buisness-news',{staticClass:"pt-40",attrs:{"darkClass":{
              item: 'business-post-item-dark',
              title: 'section-title-2',
            }}})],1),_c('div',{staticClass:"col-lg-4"},[_c('div',{staticClass:"all-post-sidebar"},[_c('div',{staticClass:"Categories-post mt-40"},[_c('div',{class:[
                  'section-title d-flex justify-content-between align-items-center',
                  _vm.dark ? 'section-title-2' : '',
                ]},[_c('h3',{staticClass:"title"},[_vm._v("Categories")]),_c('a',{attrs:{"href":"#"}},[_vm._v("ALL SEE")])]),_c('div',{staticClass:"Categories-item"},[(_vm.nav_items)?_c('ul',_vm._l((_vm.nav_items),function(item,index){return _c('li',{key:index},[(item.child)?_c('a',[_vm._v(" "+_vm._s(item.linkText)+" "),(item.child)?_c('i',{staticClass:"fal fa-angle-down"}):_vm._e()]):_c('router-link',{attrs:{"to":`/category/${item.link}`}},[_c('div',{staticClass:"item"},[_c('img',{attrs:{"src":require("@/assets/images/categories-1.jpg"),"alt":"categories"}}),_c('div',{staticClass:"Categories-content"},[_c('a',{attrs:{"href":"#"}},[_c('span',[_vm._v(" "+_vm._s(item.linkText))]),_c('img',{attrs:{"src":require("@/assets/images/arrow.svg"),"alt":""}})])])])]),(item.child)?_c('ul',{staticClass:"sub-menu"},_vm._l((item.submenu),function(lvlTwo,index){return _c('li',{key:index},[_c('router-link',{attrs:{"to":`${lvlTwo.link}`}},[_vm._v(" "+_vm._s(lvlTwo.linkText)+" "),(lvlTwo.child)?_c('i',{staticClass:"fal fa-angle-down"}):_vm._e()]),(lvlTwo.child)?_c('ul',{staticClass:"sub-menu"},_vm._l((lvlTwo.third_menu),function(lvlThree,index){return _c('li',{key:index},[_c('router-link',{attrs:{"to":`${lvlThree.link}`}},[_vm._v(_vm._s(lvlThree.linkText)+" ")])],1)}),0):_vm._e()],1)}),0):_vm._e()],1)}),0):_vm._e()]),_vm._m(1)])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"post-add mt-30"},[_c('a',{attrs:{"href":"#"}},[_c('img',{attrs:{"src":require("@/assets/images/ads/one_ad.png"),"alt":"ad"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sidebar-add pt-35"},[_c('a',{attrs:{"href":"#"}},[_c('img',{attrs:{"src":require("@/assets/images/ads/two_ad.jpg"),"alt":"ad"}})])])
}]

export { render, staticRenderFns }