var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"business-news-post"},[_c('div',{class:[
      'section-title d-flex justify-content-between align-items-center',
      _vm.darkClass && 'title' in _vm.darkClass ? _vm.darkClass.title : '',
    ]},[_c('h3',{staticClass:"title"},[_vm._v("Business News")]),_c('a',{attrs:{"href":"#"}},[_vm._v("ALL SEE")])]),_c('div',{staticClass:"business-post"},[_vm._l((_vm.businessNews.slice(0, 10)),function(data,index){return [_c('div',{key:index,class:[
          'business-post-item  mb-40',
          _vm.darkClass && 'item' in _vm.darkClass ? _vm.darkClass.item : '',
        ]},[_c('divide-card',{attrs:{"readMore":true,"stype":"row","datas":data}})],1)]})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }