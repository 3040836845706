<template>
  <HomeTwoTwo/>
</template>

<script>
import HomeTwoTwo from "../components/HomeTwo/_v2/index";

export default {
  components: {
    HomeTwoTwo,
  },
};
</script>

<style>
</style>