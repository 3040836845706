<template>
    <a :href="'/posts/postOne/' + datas.id" class="d-block" target="_blank">
    <div class="post_gallery_play">
      <div class="bg-image">
        <img
            width="730"
            :src="datas.thumbnail"
            @error="imageloaderror"
            alt=""
            style="width: 100%; height: 100%"
        />
      </div>
      <div class="post__gallery_play_content" style="z-index: 10">
        <div class="post-meta">
          <div class="meta-categories">
            <a href="#">{{ datas.category.name }}</a>
          </div>
          <div class="meta-date">
            <span>{{ datas.category.create_At }}</span>
          </div>
        </div>
        <h2 class="title">
          <a :href="'/posts/postOne/' + datas.id" target="_blank">{{ datas.title }}</a>
        </h2>
        <p>
          {{ datas.description }}......
        </p>
      </div>
      <template v-if="datas.videoUrl">
        <div class="post_play_btn"
             @click.prevent="$store.dispatch('toggleVideo')">
          <a class="video-popup"
             :href="datas.videoUrl">
            <i class="fas fa-play"></i>
          </a>
        </div>
      </template>
    </div>
    </a>
</template>

<script>
export default {
  props: ["datas"],
  methods: {
    imageloaderror(event) {
      event.target.src = `https://source.unsplash.com/random/400x300?count=`+Math.random();
    }
  }
};
</script>

<style></style>
