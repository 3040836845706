<template>
  <div>
    <drawer @toggleSidebar="toggleSidebar" :sidebar="sidebar"/>
    <Header @toggleSidebar="toggleSidebar"/>
    <!--====== POST LAYOUT 1 PART START ======-->

    <section class="post-layout-1-area pb-80">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="about-author-content">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item"><a href="/">Home</a></li>
                  <li class="breadcrumb-item"><a
                      :href="'/category/'+article[0].category.name">{{ article[0].category.name }}</a></li>
                  <!--                  <li class="breadcrumb-item active" aria-current="page">-->
                  <!--                    {{article[0].category.name}}-->
                  <!--                  </li>-->
                </ol>
              </nav>
            </div>
          </div>
          <div class="col-lg-8">
            <div class="post-layout-top-content">
              <div
                  class="
                  post-categories
                  d-flex
                  justify-content-between
                  align-content-center
                "
              >
                <div class="categories-item">
                  <span>{{ article[0].category.name }}</span>
                </div>
                <div class="categories-share">
                  <ul>
                    <!--                    <li><i class="fas fa-comment"></i>45020</li>-->
                    <li><i class="fas fa-fire"></i>{{ article[0].count }}</li>
                  </ul>
                </div>
              </div>
              <div class="post-content">
                <h3 class="title">
                  {{ article[0].title }}
                </h3>
                <p v-html="article[0].content"/>


                <!--                <div class="thumb">
                                  <img
                                       v-if="article[0].image"
                                       :src="article[0].image"
                                       @error="imageloaderror" alt="feature"/>
                                </div>-->
              </div>
              <!--              <div class="post-author">
                              <div class="author-info">
                                <div class="thumb">
                                  <img src="@/assets/images/jiaomao.png" alt=""/>
                                </div>
                                <h5 class="title">{{article[0].author_name}}</h5>
                                <ul>
                                  <li>{{article[0].publish_time}}</li>
              &lt;!&ndash;                    <li>Updated 1:58 p.m. ET</li>&ndash;&gt;
                                </ul>
                              </div>
                              <div class="author-social">&lt;!&ndash;
                                <ul>
                                  <li>
                                    <a href="#"><i class="fab fa-facebook-f"></i></a>
                                  </li>
                                  <li>
                                    <a href="#"><i class="fab fa-twitter"></i></a>
                                  </li>
                                  <li>
                                    <a href="#"><i class="fab fa-youtube"></i></a>
                                  </li>
                                  <li>
                                    <a href="#"><i class="fab fa-instagram"></i></a>
                                  </li>
                                  <li>
                                    <a href="#"><i class="fal fa-heart"></i></a>
                                  </li>
                                  <li>
                                    <a href="#"><i class="fal fa-bookmark"></i></a>
                                  </li>
                                  <li>
                                    <a href="#"><i class="fas fa-ellipsis-v"></i></a>
                                  </li>
                                </ul>
                              &ndash;&gt;</div>
                            </div>-->
              <div class="post-text mt-30"></div>
              <div class="post-text pt-20"></div>
              <div class="post-text pt-20"></div>
              <div class="post-text pt-20"></div>
              <div class="post-quote d-block d-md-flex align-items-center"></div>
              <div v-html="article[0].article" class="post-text mt-35">
              </div>
              <div class="post-tags">
                <ul>
                  <li>
                    <a href="#"><i class="fas fa-tag"></i> Tags</a>
                  </li>
                  <li><a :href="'/category/'+article[0].category.name">{{ article[0].category.name }}</a></li>
                  <!--                  <li><a href="#">World</a></li>-->
                  <!--                  <li><a href="#">Corona</a></li>-->
                </ul>
              </div>
              <div class="post-reader-text pt-50">
                <div class="row">
                  <div class="col-md-6">
                    <div class="post-reader-prev">
                      <span>PREVIOUS NEWS</span>
                      <div class="post-content">
                        <h4 class="title">
                          <a :href="'/posts/postOne/' + Posts[Posts.length-1].id" class="line-clumb"
                             target="_blank">{{ Posts[Posts.length - 1].title }}</a>
                        </h4>
                        <p class="line-clumb-one">{{ Posts[Posts.length - 1].description }}</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="post-reader-prev">
                      <span>NEXT NEWS</span>
                      <div class="post-content">
                        <h4 class="title">
                          <a :href="'/posts/postOne/' + Posts[Posts.length-2].id" class="line-clumb"
                             target="_blank">{{ Posts[Posts.length - 2].title }}</a>
                        </h4>
                        <p class="line-clumb-one">{{ Posts[Posts.length - 2].description }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <style-one :datas="Posts"/>
          </div>
        </div>
      </div>
    </section>

    <!--====== POST LAYOUT 1 PART ENDS ======-->

    <!--====== LATEST NEWS PART START ======-->

    <our-latest-news :Posts="Posts"/>

    <!--====== LATEST NEWS PART ENDS ======-->

    <!--====== POST FORM PART START ======-->
    <!--
        <div class="post-form-area">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-lg-10">
                <div class="section-title">
                  <h3 class="title">Leave an opinion</h3>
                </div>
                <div class="post-form-box">
                  <form action="#">
                    <div class="row">
                      <div class="col-lg-6">
                        <div class="input-box">
                          <input type="text" placeholder="Full name"/>
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="input-box">
                          <input type="text" placeholder="Email address"/>
                        </div>
                      </div>
                      <div class="col-lg-12">
                        <div class="input-box">
                          <textarea
                              name="#"
                              id="#"
                              cols="30"
                              rows="10"
                              placeholder="Tell us about your opinion…"
                          ></textarea>
                          <button class="main-btn" type="button">
                            POST OPINION
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>-->

    <!--====== POST FORM PART ENDS ======-->

    <!--====== POST COMMENTS PART START ======-->

    <!--    <section class="post-comments-area pb-100">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-lg-10">
                <div class="section-title">
                  <h3 class="title">Post Comments</h3>
                </div>
                <div class="post-comments-list">
                  <div class="post-comments-item">
                    <div class="thumb">
                      <img src="@/assets/images/comments-1.png" alt="comments"/>
                    </div>
                    <div class="post">
                      <a href="#">Reply</a>
                      <h5 class="title">Rafiqul islam</h5>
                      <p>
                        We’ve invested every aspect of how we serve our users over
                        the past Pellentesque rutrum ante in nulla suscipit, vel
                        posuere leo tristique.
                      </p>
                    </div>
                  </div>
                  <div class="post-comments-item">
                    <div class="thumb">
                      <img src="@/assets/images/comments-2.png" alt="comments"/>
                    </div>
                    <div class="post">
                      <a href="#">Reply</a>
                      <h5 class="title">Rafiqul islam</h5>
                      <p>
                        We’ve invested every aspect of how we serve our users over
                        the past Pellentesque rutrum ante in nulla suscipit, vel
                        posuere leo tristique.
                      </p>
                    </div>
                  </div>
                  <div class="post-comments-item ml-30">
                    <div class="thumb">
                      <img src="@/assets/images/comments-3.png" alt="comments"/>
                    </div>
                    <div class="post">
                      <a href="#">Reply</a>
                      <h5 class="title">Rafiqul islam</h5>
                      <p>
                        We’ve invested every aspect of how we serve our users over
                        the past Pellentesque rutrum ante in nulla suscipit, vel
                        posuere leo tristique.
                      </p>
                    </div>
                  </div>
                  <div class="post-comments-item">
                    <div class="thumb">
                      <img src="@/assets/images/comments-4.png" alt="comments"/>
                    </div>
                    <div class="post">
                      <a href="#">Reply</a>
                      <h5 class="title">Rafiqul islam</h5>
                      <p>
                        We’ve invested every aspect of how we serve our users over
                        the past Pellentesque rutrum ante in nulla suscipit, vel
                        posuere leo tristique.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="post-load-btn">
                  <a class="main-btn" href="#">LOAD MORE</a>
                </div>
              </div>
            </div>
          </div>
        </section>-->

    <!--====== POST COMMENTS PART ENDS ======-->

    <!--====== ADD PART ENDS ======-->

    <div class="add-area text-center">
      <a href="#">
        <img src="@/assets/images/ads/one_ad.png" alt=""/>
      </a>
    </div>

    <!--====== ADD PART ENDS ======-->

    <footer-one/>
    <div class="go-top-area">
      <div class="go-top-wrap">
        <div class="go-top-btn-wrap">
          <a href="#" class="go-top go-top-btn d-block">
            <i class="fa fa-angle-double-up"></i>
            <i class="fa fa-angle-double-up"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "../../Utility/Header/index";
import StyleOne from "../../Utility/Sidebar/StyleOne.vue";
import Posts from "../../Data/NewsRowCard";
import FooterOne from "../../Utility/Footer/FooterOne.vue";
import OurLatestNews from "../../Utility/Common/OurLatestNews.vue";
import Drawer from "../../Mobile/Drawer.vue";
// import axios from "axios";

export default {
  components: {Header, StyleOne, FooterOne, OurLatestNews, Drawer},
  data: () => ({
    Posts: Posts.data,
    sidebar: false,
    article: Posts.data,
  }),
  created() {
    document.addEventListener("scroll", this.topToBottom);
    this.fetchPostItems();
    this.fetchPostArticle();
  },
  methods: {
    toggleSidebar() {
      this.sidebar = !this.sidebar;
    },
    topToBottom() {
      const result = document.querySelector(".go-top");
      if (
          document.body.scrollTop > window.innerHeight ||
          document.documentElement.scrollTop > window.innerHeight
      ) {
        result.classList.add("active");
      } else {
        result.classList.remove("active");
      }
    },
    imageloaderror(event) {
      event.target.src = `https://source.unsplash.com/random/400x300?count=`+Math.random();
    },
    fetchPostItems() {
      this.$root.cachedAxios
          .get(this.$root.apiUrl + 'news/' + this.$root.zone + "?queryType=default") // Replace with your server URL
          .then(response => {
            this.Posts = response.data['data'];
          })
          .catch(error => {
            this.error = error;
            console.error('Error fetching data:', error);
          });
    },
    fetchPostArticle() {
      this.$root.cachedAxios.get(this.$root.apiUrl + 'news/' + this.$root.zone + "?queryType=post&postId=" + this.$route.params.id) // Replace with your server URL
          .then(response => {
            this.article = response.data['data'];
          })
          .catch(error => {
            this.error = error;
            console.error('Error fetching data:', error);
          });
    },
  },
};
</script>

<style></style>
