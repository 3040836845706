<template>
  <div class="business-news-post">
    <div
        :class="[
        'section-title d-flex justify-content-between align-items-center',
        darkClass && 'title' in darkClass ? darkClass.title : '',
      ]"
    >
      <h3 class="title">Business News</h3>
      <a href="#">ALL SEE</a>
    </div>
    <div class="business-post">
      <template v-for="(data, index) in businessNews.slice(0, 10)">
        <div
            :key="index"
            :class="[
            'business-post-item  mb-40',
            darkClass && 'item' in darkClass ? darkClass.item : '',
          ]"
        >
          <divide-card :readMore="true" stype="row" :datas="data"/>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import DivideCard from "../Utility/Cards/DivideCard.vue";
import TrendingPosts from "../Data/TrendingPost";
// import axios from "axios";

export default {
  components: {DivideCard},
  props: {
    darkClass: {
      type: Object,
    },
  },
  data: () => ({
    businessNews: TrendingPosts.data,
  }),
  created() {
    this.fetchPostItems();
  },
  methods: {
    fetchPostItems() {
      this.$root.cachedAxios
          .get(this.$root.apiUrl + 'news/' + this.$root.zone + "?queryType=businessNews") // Replace with your server URL
          .then(response => {
            this.businessNews = response.data['data'];
          })
          .catch(error => {
            this.error = error;
            console.error('Error fetching data:', error);
          });
    },
  },
};
</script>

<style>
</style>