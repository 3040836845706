<template>
  <div class="header-topbar">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-8">
          <div class="topbar-trending">
            <span>头条：</span>
            <div class="trending-slider">
              <span
                  @click="prev"
                  class="prev slick-arrow"
                  style="display: block"
              ><i class="fal fa-angle-left"></i
              ></span>
              <slider :settings="settings" ref="carousel">
                <template v-for="(data, index) in news">
                  <div :key="index" class="trending-item">
                    <p>
                      {{ data.news }}
                    </p>
                  </div>
                </template>
              </slider>
              <span
                  @click="next"
                  class="next slick-arrow"
                  style="display: block"
              ><i class="fal fa-angle-right"></i
              ></span>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="topbar-social d-flex align-items-center">
            <p>{{ currentDateTime }}</p>
            <div class="social">
              <ul>
                <li>
                  <a href="#"><i class="fab fa-facebook-f"></i></a>
                </li>
                <li>
                  <a href="#"><i class="fab fa-twitter"></i></a>
                </li>
                <li>
                  <a href="#"><i class="fab fa-youtube"></i></a>
                </li>
                <li>
                  <a href="#"><i class="fab fa-instagram"></i></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import Slider from "../../Helpers/Slider.vue";
// import axios from 'axios'; // Import axios for making API calls
import json from "../../Data/TrandingHeaderOneTopBar";
import VueFilterDateFormat, {dateFormat} from '@vuejs-community/vue-filter-date-format';

Vue.use(VueFilterDateFormat);

export default {
  components: {Slider},
  data() {
    return {
      settings: {
        dots: false,
        arrows: false,
        autoplay: false,
      },
      news: json.datas, // Initialize news array to store fetched data
      loading: true, // Flag to indicate loading state
      error: null,
      currentDateTime: null, // Store any errors that occur
    };
  },
  created() {
    // Fetch news data on component creation
    this.fetchNews();
  },
  methods: {
    dateFormat,
    fetchNews() {
      this.$root.cachedAxios
          .get(this.$root.apiUrl + 'trendingHeaderOneTopBar/' + this.$root.zone) // Replace with your server URL
          .then(response => {
            this.news = response.data['datas'];
          })
          .catch(error => {
            this.error = error;
            console.error('Error fetching news:', error);
          });
    },
    next() {
      this.$refs.carousel.next();
    },
    prev() {
      this.$refs.carousel.prev();
    },
    getNow: function () {
      this.currentDateTime = dateFormat(new Date());
    }
  },
  mounted() {
    setInterval(() => {
      this.getNow();
    }, 1000);
  }
};
</script>

<style>
</style>