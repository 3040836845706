var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"video-news-area"},[_c('div',{staticClass:"container custom-container"},[_c('div',{class:[
        'video-news-box',
        _vm.darkClass && 'section' in _vm.darkClass ? _vm.darkClass.section : '',
      ]},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-8"},[_c('div',{staticClass:"video-news-post"},[_vm._m(0),_c('div',{class:[
                'video-news-post-item',
                _vm.darkClass && 'section_2' in _vm.darkClass
                  ? _vm.darkClass.section_2
                  : '',
              ]},[_c('div',{staticClass:"video-news-post-thumb"},[(_vm.smallPostGallery[0].image)?_c('img',{attrs:{"height":"422","src":_vm.smallPostGallery[0].image,"alt":"gallery"},on:{"error":_vm.imageloaderror}}):_vm._e(),_c('div',{staticClass:"play-btn"},[_c('a',{staticClass:"video-popup",on:{"click":function($event){$event.preventDefault();return _vm.$store.dispatch('toggleVideo')}}},[_c('i',{staticClass:"fab fa-youtube"})])])]),_c('div',{staticClass:"video-news-post-content"},[_c('div',{staticClass:"post-meta"},[_c('div',{staticClass:"meta-categories"},[_c('a',{attrs:{"href":"#"}},[_vm._v(_vm._s(_vm.smallPostGallery[0].category.name))])]),_c('div',{staticClass:"meta-date"},[_c('span',[_vm._v(_vm._s(_vm.smallPostGallery[0].publish_time))])])]),_c('h3',{staticClass:"title"},[_c('a',{staticClass:"line-clumb",attrs:{"href":'/posts/postOne/' + _vm.smallPostGallery[0].id,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.smallPostGallery[0].description)+" ")])])])])])]),_c('div',{staticClass:"col-lg-4"},[_c('div',{staticClass:"populer-post"},[_c('div',{class:['section-title', _vm.dark ? 'section-title-2' : '']},[_c('h3',{staticClass:"title"},[_vm._v("Popular")])]),_c('div',{staticClass:"trending-sidebar-slider position-relative"},[_c('span',{staticClass:"prev slick-arrow",staticStyle:{"display":"block"},on:{"click":_vm.tssPrev}},[_c('i',{staticClass:"fal fa-angle-left"})]),_c('slider',{ref:"trendingSidebarSlide",attrs:{"settings":_vm.videoNewsSlide}},[_c('div',{staticClass:"post_gallery_items"},[_vm._l((_vm.smallPostGallery.slice(4, 7)),function(small,index){return [_c('row-card',{key:index,class:[
                        'populer_item-style',
                        _vm.darkClass && 'news_item' in _vm.darkClass
                          ? _vm.darkClass.news_item
                          : '',
                      ],attrs:{"trending":true,"datas":small}})]})],2),_c('div',{staticClass:"post_gallery_items"},[_vm._l((_vm.smallPostGallery.slice(7, 10)),function(small,index){return [_c('row-card',{key:index,class:[
                        'populer_item-style',
                        _vm.darkClass && 'news_item' in _vm.darkClass
                          ? _vm.darkClass.news_item
                          : '',
                      ],attrs:{"trending":true,"datas":small}})]})],2)]),_c('span',{staticClass:"next slick-arrow",staticStyle:{"display":"block"},on:{"click":_vm.tssNext}},[_c('i',{staticClass:"fal fa-angle-right"})])],1)])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section-title section-title-2"},[_c('h3',{staticClass:"title"},[_vm._v("Videos News")])])
}]

export { render, staticRenderFns }