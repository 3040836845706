<template>
  <div>
    <HomeOneTwo/>
  </div>
</template>

<script>
import HomeOneTwo from "../components/Home/_v2/index";

export default {
  components: {HomeOneTwo},
};
</script>

<style>
</style>