<template>
  <div
      :class="[
      'newspark-header-main-menu',
      darkClass && 'menuItem' in darkClass ? darkClass.menuItem : '',
    ]"
  >
    <ul v-if="nav_items">
      <li v-for="(item, index) in nav_items" :key="index">
        <a v-if="item.child">
          {{ item.linkText }}
          <i v-if="item.child" class="fal fa-angle-down"></i>
        </a>
<!--        <router-link v-else :to="`/category/${item.link}`">-->
<!--          {{item.linkText}}-->
<!--        </router-link>-->

          <a v-else :href="'/category/' + item.link" target="_blank">{{ item.linkText }}</a>

        <ul v-if="item.child" class="sub-menu">
          <li v-for="(lvlTwo, index) in item.submenu" :key="index">
            <router-link :to="`${lvlTwo.link}`">
              {{ lvlTwo.linkText }}
              <i v-if="lvlTwo.child" class="fal fa-angle-down"></i>
            </router-link>
            <ul v-if="lvlTwo.child" class="sub-menu">
              <li v-for="(lvlThree, index) in lvlTwo.third_menu" :key="index">
                <router-link :to="`${lvlThree.link}`">{{
                    lvlThree.linkText
                  }}
                </router-link>
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
import navItems from "../../Data/NavItems.json";
// import axios from 'axios';

export default {
  props: {
    darkClass: {
      type: Object,
    },
  },
  data: () => ({
    nav_items: navItems,
  }),
  created() {
    this.fetchNavItems();
  },
  methods: {
    fetchNavItems() {
      this.$root.cachedAxios
          .get(this.$root.apiUrl + 'navItems/' + this.$root.zone)
          .then(response => {
            this.nav_items = response.data['data'];
          })
          .catch(error => {
            this.error = error;
            console.error('Error fetching data:', error);
          });
    },
  },
};
</script>

<style>
.newspark-header-main-menu.header-two ul > li > a {
  color: #ffffff;
}

.newspark-header-main-menu.header-three-nav ul > li > a {
  color: white;
}

.header-three-nav {
  margin-left: 35px;
}
</style>
