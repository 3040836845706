<template>
  <section class="all-post-area">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-8">
          <entertainment-news v-if="!dark"/>
          <entertainment-news
              v-else
              :darkClass="{
              item: 'trending-news-item-dark',
              title: 'section-title-2',
            }"
          />
          <sports-news v-if="!dark"/>
          <sports-news
              v-else
              :darkClass="{
              item: 'trending-news-item-dark',
              title: 'section-title-2',
              news_item: 'gallery_item_dark',
            }"
          />
          <div class="post-add mt-30">
            <a href="#"
            ><img src="@/assets/images/ads/one_ad.png" alt="ad"
            /></a>
          </div>
          <buisness-news class="pt-40" v-if="!dark"/>
          <buisness-news
              v-else
              class="pt-40"
              :darkClass="{
              item: 'business-post-item-dark',
              title: 'section-title-2',
            }"
          />
        </div>
        <div class="col-lg-4">
          <div class="all-post-sidebar">
<!--            <share-post v-if="!dark"/>
            <share-post
                v-else
                :darkClass="{
                title: 'section-title-2',
                item: 'most-share-post-item-dark',
              }"
            />
            <div class="upcoming-matches-post mt-35">
              <div
                  :class="[
                  'section-title d-flex justify-content-between align-items-cente',
                  dark ? 'section-title-2' : '',
                ]"
              >
                <h3 class="title">Upcoming Matches</h3>
                <a href="#">ALL SEE</a>
              </div>
              <div class="upcoming-matches-post-items">
                <div
                    :class="[
                    'upcoming-matches-post-item d-flex align-items-center',
                    dark ? 'upcoming-matches-post-item-dark' : '',
                  ]"
                >
                  <div class="upcoming-matches-flag">
                    <img src="@/assets/images/flag/flag-1.png" alt="flag"/>
                  </div>
                  <div class="upcoming-matches-content">
                    <span>Germany <span>VS</span> France</span>
                    <ul>
                      <li>Tomorrow</li>
                      <li>22:30 (CST)</li>
                    </ul>
                  </div>
                  <div class="upcoming-matches-circle">
                    <div class="circle-bar">
                      <div class="first circle"></div>
                    </div>
                  </div>
                </div>
                <div
                    :class="[
                    'upcoming-matches-post-item d-flex align-items-center',
                    dark ? 'upcoming-matches-post-item-dark' : '',
                  ]"
                >
                  <div class="upcoming-matches-flag">
                    <img src="@/assets/images/flag/flag-2.png" alt="flag"/>
                  </div>
                  <div class="upcoming-matches-content">
                    <span>Spain <span>VS</span> Portugal</span>
                    <ul>
                      <li>Tomorrow</li>
                      <li>22:30 (CST)</li>
                    </ul>
                  </div>
                  <div class="upcoming-matches-circle">
                    <div class="circle-bar">
                      <div class="first circle"></div>
                    </div>
                  </div>
                </div>
                <div
                    :class="[
                    'upcoming-matches-post-item d-flex align-items-center',
                    dark ? 'upcoming-matches-post-item-dark' : '',
                  ]"
                >
                  <div class="upcoming-matches-flag">
                    <img src="@/assets/images/flag/flag-3.png" alt="flag"/>
                  </div>
                  <div class="upcoming-matches-content">
                    <span>Russia <span>VS</span> Italy</span>
                    <ul>
                      <li>Tomorrow</li>
                      <li>22:30 (CST)</li>
                    </ul>
                  </div>
                  <div class="upcoming-matches-circle">
                    <div class="circle-bar">
                      <div class="first circle"></div>
                    </div>
                  </div>
                </div>
                <div
                    :class="[
                    'upcoming-matches-post-item d-flex align-items-center',
                    dark ? 'upcoming-matches-post-item-dark' : '',
                  ]"
                >
                  <div class="upcoming-matches-flag">
                    <img src="@/assets/images/flag/flag-4.png" alt="flag"/>
                  </div>
                  <div class="upcoming-matches-content">
                    <span>Croatia <span>VS</span> England</span>
                    <ul>
                      <li>Tomorrow</li>
                      <li>22:30 (CST)</li>
                    </ul>
                  </div>
                  <div class="upcoming-matches-circle">
                    <div class="circle-bar">
                      <div class="first circle"></div>
                    </div>
                  </div>
                </div>
                <div
                    :class="[
                    'upcoming-matches-post-item d-flex align-items-center',
                    dark ? 'upcoming-matches-post-item-dark' : '',
                  ]"
                >
                  <div class="upcoming-matches-flag">
                    <img src="@/assets/images/flag/flag-5.png" alt="flag"/>
                  </div>
                  <div class="upcoming-matches-content">
                    <span>Germany <span>VS</span> France</span>
                    <ul>
                      <li>Tomorrow</li>
                      <li>22:30 (CST)</li>
                    </ul>
                  </div>
                  <div class="upcoming-matches-circle">
                    <div class="circle-bar">
                      <div class="first circle"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>-->
<!--            <div
                :class="[
                'newsletter-box mt-45',
                dark ? 'newsletter-box-dark' : '',
              ]"
            >
              <h5 class="title">Newsletter</h5>
              <p>
                Your email address will not be this published. Required fields
                are News Today.
              </p>
              <form action="#">
                <div class="input-box">
                  <input type="text" placeholder="Your email address"/>
                  <button type="button">SIGN UP</button>
                </div>
              </form>
              <span>We hate spam as much as you do</span>
            </div>-->
            <div class="Categories-post mt-40">
              <div
                  :class="[
                  'section-title d-flex justify-content-between align-items-center',
                  dark ? 'section-title-2' : '',
                ]"
              >
                <h3 class="title">Categories</h3>
                <a href="#">ALL SEE</a>
              </div>
              <div class="Categories-item">
                <ul v-if="nav_items">
                  <li v-for="(item, index) in nav_items" :key="index">
                    <a v-if="item.child">
                      {{ item.linkText }}
                      <i v-if="item.child" class="fal fa-angle-down"></i>
                    </a>
                    <router-link v-else :to="`/category/${item.link}`">
                      <div class="item">
                        <img src="@/assets/images/categories-1.jpg"
                            alt="categories"/>
                        <div class="Categories-content">
                          <a href="#">
                            <span> {{item.linkText}}</span>
                            <img src="@/assets/images/arrow.svg" alt=""/>
                          </a>
                        </div>
                      </div>
                    </router-link>
                    <ul v-if="item.child" class="sub-menu">
                      <li v-for="(lvlTwo, index) in item.submenu" :key="index">
                        <router-link :to="`${lvlTwo.link}`">
                          {{ lvlTwo.linkText }}
                          <i v-if="lvlTwo.child" class="fal fa-angle-down"></i>
                        </router-link>
                        <ul v-if="lvlTwo.child" class="sub-menu">
                          <li v-for="(lvlThree, index) in lvlTwo.third_menu" :key="index">
                            <router-link :to="`${lvlThree.link}`">{{
                                lvlThree.linkText
                              }}
                            </router-link>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </ul>

              </div>
              <div class="sidebar-add pt-35">
                <a href="#"
                ><img src="@/assets/images/ads/two_ad.jpg" alt="ad"
                /></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import BuisnessNews from "../Home/BuisnessNews.vue";
import EntertainmentNews from "../Home/EntertainmentNews.vue";
// import SharePost from "../Home/SharePost.vue";
import SportsNews from "../Home/SportsNews.vue";
import navItems from "@/components/Data/NavItems.json";
// import axios from "axios";

export default {
  props: {
    dark: {
      type: Boolean,
      default: false,
    },
  },
  components: {EntertainmentNews, SportsNews, BuisnessNews},
  data: () => ({
    nav_items: navItems,
  }),
  created() {
    this.fetchNavItems();
  },
  methods: {
    fetchNavItems() {
      this.$root.cachedAxios
          .get(this.$root.apiUrl + 'navItems/' + this.$root.zone)
          .then(response => {
            this.nav_items = response.data['data'];
          })
          .catch(error => {
            this.error = error;
            console.error('Error fetching data:', error);
          });
    },
  },
};
</script>

<style>
</style>